import React, { createContext, useContext, useState, useEffect } from 'react';
import { auth, firestore } from '../firebaseConfig';
import { limit } from 'firebase/firestore';

// Create Context
const UserContext = createContext();

// Custom hook to use the context
export const useUser = () => useContext(UserContext);

// Provider Component
export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async firebaseUser => {
      if (firebaseUser) {
        const userRef = firestore.doc(`users-v2/${firebaseUser.uid}`);
        const userSnap = await userRef.get();

        if (!userSnap.exists) {
          const { displayName, email } = firebaseUser;
          const userData = { displayName, email, isPro: false, limit: 10 };
          userRef.set(userData);
          setUser({ uid: firebaseUser.uid, ...userData });
        } else {
          setUser({ uid: firebaseUser.uid, ...userSnap.data() });
        }
      } else {
        setUser(null);
      }
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  return (
    <UserContext.Provider value={{ user, loading }}>
      {children}
    </UserContext.Provider>
  );
};
