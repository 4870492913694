import React from 'react';
import { Box, Tabs, TabList, TabPanels, Tab, TabPanel } from '@chakra-ui/react';
import ImageResizerTab from './ImageResizerTab';
import ImageCompressionTab from './ImageCompressionTab';
import { useUser } from '../context/UserContext';


const MainContainer = () => {
  const { user, loading } = useUser();

  return (
    <Box p={4} align="center" justify="center" borderRadius="lg" m={4} flex="1">
      <Tabs variant='soft-rounded' colorScheme='purple' isFitted maxW="780px">
        <TabList mb="1em">
          <Tab>Resize Images</Tab>
          <Tab isDisabled={!user || user.isPro === false}>
            Compress Images
            <Box as="span" bgColor="white" color="purple.500" ml={2} pl={2} pr={2} rounded={10}>
              PRO only
            </Box>
          </Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
            <ImageResizerTab />
          </TabPanel>
          <TabPanel>
            <ImageCompressionTab />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  );
};

export default MainContainer;
