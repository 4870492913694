import React from 'react';
import { Box, Text, Link, Stack } from '@chakra-ui/react';

const Footer = () => {
  return (
    <Box px={4} py={3} textAlign="center">
      <Text>© 2024 SnapShrink.com - All rights reserved.</Text>
      <Stack direction="row" spacing={4} justify="center" mt={4}>
        <Link href="https://insigh.to/b/snapshrink.com" isExternal color="teal.500">Feedback?</Link>
        <Link href="/tos.html" color="teal.500">Terms and Conditions</Link>
        <Link href="/privacy-policy.html" color="teal.500">Privacy Policy</Link>
        <Link href="https://www.twitter.com/GameDevWeirdo" isExternal color="teal.500">Twitter</Link>
        <Link href="https://www.github.com/gdwrd" isExternal color="teal.500">Github</Link>
      </Stack>
    </Box>
  );
};

export default Footer;
