import React, { useEffect, useState } from 'react';
import firebase from 'firebase/compat/app';
import { Button, Box, Flex, Text, Link, useColorMode, Switch, FormLabel, useToast } from '@chakra-ui/react';
import { auth, firestore } from '../firebaseConfig';

const Header = () => {
  const { colorMode, toggleColorMode } = useColorMode();
  const isDarkMode = colorMode === 'dark';
  const toast = useToast();

  const [user, setUser] = useState(null);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async user => {
      console.log(user);
      if (user) {
        const userRef = firestore.doc(`users-v2/${user.uid}`);
        const userSnap = await userRef.get();

        if (!userSnap.exists) {
          const { displayName, email } = user;
          console.log('Creating user:', user);
          userRef.set({ displayName, email, isPro: false, limit: 10 });
        } else {
          const { isPro } = userSnap.data();
          user.isPro = isPro;
        }

        setUser(user);
      } else {
        setUser(null);
      }
    });

    return () => unsubscribe();
  }, []);

  const signInWithGoogle = () => {
    const provider = new firebase.auth.GoogleAuthProvider();
    auth.signInWithPopup(provider).then(result => {
      toast({
        title: 'Hello! 👋',
        description: "You've successfully signed in.",
        status: 'success',
        duration: 9000,
        isClosable: true,
        position: 'top',
      });
    }).catch(error => {
      toast({
        title: 'Error',
        description: "An error occurred while signing in. Please try again.",
        status: 'error',
        duration: 9000,
        isClosable: true,
        position: 'top',
      });
    });
  };

  const signOut = () => {
    auth.signOut().then(() => {
      toast({
        title: 'Goodbye! 👋',
        description: "You've successfully signed out.",
        status: 'info',
        duration: 9000,
        isClosable: true,
        position: 'top',
      });
    });
  };

  return (
    <Box boxShadow='lg' px={4} py={3}>
      <Flex justify="space-between" align="center">
        <Link href="/" style={{textDecoration: 'none'}} passHref>
          <Flex align="center">
            <img src="/logo.png" alt="Logo" style={{ width: '50px', height: '50px' }} />
            <Text display={{ base: "none", md: "block" }} ml={3} fontSize="xl" fontWeight="regular">SnapShrink.com</Text>
          </Flex>
        </Link>
        <Flex align="center">
          <FormLabel htmlFor="dark-mode-switch" mb="0">
            {isDarkMode ? '🌙' : '☀️'}
          </FormLabel>
          <Switch id="dark-mode-switch" isChecked={isDarkMode} onChange={toggleColorMode} colorScheme="purple" />
          {user ? (
            <>
              {user.isPro && (
                <Button ml={4} disabled colorScheme="gray">
                  PRO
                </Button>
              )}
            </>
          ) : (
            <Button ml={4} onClick={signInWithGoogle} colorScheme="gray">
              Try for Free
            </Button>
          )}
          {user ? (
            <Button ml={4} onClick={signOut} colorScheme="gray">
              <img src={user.photoURL} alt={user.displayName} style={{ width: '24px', height: '24px', borderRadius: '50%' }} />
              <Text ml={2} > Sign out</Text>
            </Button>
          ) : (
            <Button ml={4} onClick={signInWithGoogle} colorScheme="gray">
              Sign in with Google
            </Button>
          )}
        </Flex>
      </Flex>
    </Box>
  );
};

export default Header;
