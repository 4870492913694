import React, { useState } from 'react';
import {
  Box, Button, Input, Select, Image as ChakraImage, SimpleGrid, Text, Progress, FormControl, FormLabel, useToast
} from '@chakra-ui/react';
import Pica from 'pica';
import JSZip from 'jszip';
import { useUser } from '../context/UserContext';
import { firestore } from '../firebaseConfig';
import firebase from 'firebase/compat/app';
import FileDropzone from './FileDropzone';

const ImageResizerTab = () => {
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [resizePercentage, setResizePercentage] = useState('50');
  const [resizeByMaxSize, setResizeByMaxSize] = useState('1024'); // Default max size
  const [resizeMethod, setResizeMethod] = useState('percentage'); // 'percentage' or 'maxSize'
  const [progress, setProgress] = useState(0);
  const [downloadUrl, setDownloadUrl] = useState('');
  const [isResizing, setIsResizing] = useState(false);
  const { user, loading } = useUser();
  const toast = useToast();
  const fileInputRef = React.useRef();

  const updateResizeCount = (count) => {
    const statsRef = firestore.doc('stats/imageStats');
    return statsRef.update({
      resizedCount: firebase.firestore.FieldValue.increment(count)
    });
  };

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (event) => {
    const files = Array.from(event.target.files).map((file) => ({
      file,
      url: URL.createObjectURL(file),
      name: file.name
    }));
    if ((files.length > 10) && (!user || user.isPro === false)) {
      toast({
        title: 'Error',
        description: "You can only upload up to 10 images at a time. Please upgrade to Pro for unlimited uploads.",
        status: 'error',
        duration: 9000,
        isClosable: true,
        position: 'top',
      });
      // Clear the input
      event.target.value = '';
      return;
    }
    setSelectedFiles(files);
    setProgress(0);
    setDownloadUrl('');
  };

  const handleFilesAdded = (files) => {
    if ((files.length > 10) && (!user || user.isPro === false)) {
      toast({
        title: 'Error',
        description: "You can only upload up to 10 images at a time. Please upgrade to Pro for unlimited uploads.",
        status: 'error',
        duration: 9000,
        isClosable: true,
        position: 'top',
      });
      // Clear the input
      files = [];
      return;
    }

    // Filter all files, and leave only images
    files = files.filter(file => file.type.startsWith('image/'));
    setSelectedFiles(files.map(file => ({
      file,
      url: URL.createObjectURL(file)
    })));

    setProgress(0);
    setDownloadUrl('');
  }

  const handleResizePercentageChange = (event) => {
    setResizePercentage(event.target.value);
  };

  const resizeImages = async () => {
    setIsResizing(true);
    const pica = Pica();
    const zip = new JSZip();
    const canvas = document.createElement('canvas');
    let processedCount = 0;

    for (const imageFile of selectedFiles) {
      const img = new Image();
      img.src = imageFile.url;

      await new Promise((resolve) => {
        img.onload = () => {
          let scale = 1;
          if (resizeMethod === 'percentage') {
            scale = parseInt(resizePercentage) / 100;
          } else {
            const maxDimension = Math.max(img.naturalWidth, img.naturalHeight);
            scale = parseInt(resizeByMaxSize) / maxDimension;
          }
          canvas.width = img.naturalWidth * scale;
          canvas.height = img.naturalHeight * scale;

          pica.resize(img, canvas)
            .then(result => pica.toBlob(result, 'image/jpeg'))
            .then(blob => {
              zip.file(imageFile.name, blob, { binary: true });
              processedCount++;
              setProgress((processedCount / selectedFiles.length) * 100);
              resolve();
            });
        };
      });
    }

    // Call updateResizeCount inside the resizing promise resolution
    updateResizeCount(selectedFiles.length).then(() => {
      console.log('Updated resize count');
    }).catch(error => {
      console.error('Error updating resize count', error);
    });

    zip.generateAsync({ type: "blob" }).then(function(content) {
      const url = URL.createObjectURL(content);
      setDownloadUrl(url);  // Save the URL to state
      setIsResizing(false);
    });
  };

  const handleDownload = () => {
    const anchor = document.createElement('a');
    anchor.href = downloadUrl;
    anchor.download = "resized_images.zip";
    document.body.appendChild(anchor);
    anchor.click();
    document.body.removeChild(anchor);
  };

  const clearImages = () => {
    setSelectedFiles([]);
    setDownloadUrl('');
    setProgress(0);
  };

  const removeImage = (index) => {
    const newSelectedFiles = selectedFiles.filter((_, i) => i !== index);
    setSelectedFiles(newSelectedFiles);
  };

  return (
    <Box>
        <Input 
        type="file" 
        accept="image/jpeg, image/png" 
        multiple 
        onChange={handleFileChange} 
        p={2} 
        ref={fileInputRef}
        style={{ display: 'none' }} // Hide the default file input
      />
      <Button onClick={handleButtonClick}>Upload Images</Button>
      <FileDropzone onFilesAdded={handleFilesAdded} />
        <FormControl id="resize-method" mt={4}>
            <FormLabel>Resize Method:</FormLabel>
            <Select onChange={(e) => setResizeMethod(e.target.value)} value={resizeMethod} placeholder="Select resize method" my={4}>
                <option value="percentage">By Percentage</option>
                <option value="maxSize">By Max Size</option>
            </Select>
        </FormControl>
        {resizeMethod === 'maxSize' && (
            <FormControl id="max-size-input" mt={4}>
                <FormLabel>Max Size of Biggest side:</FormLabel>
                <Input type="number" value={resizeByMaxSize} onChange={(e) => setResizeByMaxSize(e.target.value)} my={4} />
            </FormControl>
        )}
        {resizeMethod === 'percentage' && (
            <FormControl id="max-size-input" mt={4}>
                <FormLabel>Resize Percentage:</FormLabel>
                <Select onChange={handleResizePercentageChange} value={resizePercentage} placeholder="Select resize percentage" my={4}>
                    <option value="10">10%</option>
                    <option value="20">20%</option>
                    <option value="50">50%</option>
                    <option value="75">75%</option>
                </Select>
            </FormControl>
        )}
        <Button colorScheme="purple" onClick={resizeImages} isDisabled={isResizing || selectedFiles.length === 0}>Resize Images</Button>
        <Button ml={2} onClick={handleDownload} isDisabled={!downloadUrl}>Download Zip</Button>
        <Button ml={2} colorScheme="red" onClick={clearImages}>Clear</Button>
        {selectedFiles.length > 0 && (
            <Box mt={4}>
                <Progress value={progress} size="sm" colorScheme="purple" />
                <Text mb={2} fontWeight="bold">Preview:</Text>
                <SimpleGrid columns={[3, 3, 6]} spacing={4}>
                    {selectedFiles.map((file, index) => (
                        <Box position="relative" key={index}>
                        <Button 
                            position="absolute"
                            right="-2" 
                            top="-2" 
                            size="xs" 
                            colorScheme="red" 
                            onClick={() => removeImage(index)}
                        >
                            x
                        </Button>
                        <ChakraImage src={file.url} alt={file.name} size="100px" objectFit="cover" borderRadius={5} />
                        </Box>
                    ))}
                </SimpleGrid>
            </Box>
        )}
    </Box>
);
};

export default ImageResizerTab;
