import React from 'react';
import { Box, Text } from '@chakra-ui/react';
import ProPlanModal from './ProPlanModel';
import { useUser } from '../context/UserContext';

const InfoComponent = () => {
  const { user, loading } = useUser();

  return (
    <Box p={5} borderRadius="md" mb={4} textAlign="center">
      <Text fontSize="lg" mb={2}>
        Welcome to SnapShrink.com!
      </Text>
      <Text mb={3}>
        Utilize our app to easily resize and compress your images. Try out the basic features for free.
      </Text>
      {user && user.isPro ? (
        <Text mb={2}>
          You are a <strong>PRO</strong> user. Enjoy unlimited image uploads!
        </Text>
      ) : (
        <>
          <Text mb={2}>
            Upgrade to <strong>PRO</strong> for advanced capabilities:
          </Text>
          <ul>
            <li>- Resize up to 200 images at once</li>
            <li>- Access to bulk image compression feature with a image limit size feature</li>
            <li>- Even more security for your images</li>
            <li>- Perfect work with slow internet connection</li>
          </ul>
          <ProPlanModal buttonText="Get PRO today with 85% off - just $0.99" />
        </>
      )}
    </Box>
  );
};

export default InfoComponent;
