import React, { useState } from 'react';
import {
  Box, Button, Input, Text, SimpleGrid, Image as ChakraImage, Progress, NumberInput, NumberInputField, FormControl, FormLabel, useToast
} from '@chakra-ui/react';
import Compressor from 'compressorjs';
import JSZip from 'jszip';
import { useUser } from '../context/UserContext';
import { firestore } from '../firebaseConfig';
import firebase from 'firebase/compat/app';
import FileDropzone from './FileDropzone';

const ImageCompressionTab = () => {
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [progress, setProgress] = useState(0);
  const [downloadUrl, setDownloadUrl] = useState('');
  const [maxSize, setMaxSize] = useState(500);
  const { user, loading } = useUser();
  const toast = useToast();
  const fileInputRef = React.useRef();

  const updateResizeCount = (count) => {
    const statsRef = firestore.doc('stats/imageStats');
    return statsRef.update({
      resizedCount: firebase.firestore.FieldValue.increment(count)
    });
  };

  const handleButtonClick = () => {
    // Check if user signed in
    if (!user) {
      toast({
        title: 'Error',
        description: "You need to sign in to upload images.",
        status: 'error',
        duration: 9000,
        isClosable: true,
        position: 'top',
      });
      return;
    }

    fileInputRef.current.click();
  };

  const handleFileChange = (event) => {
    const files = Array.from(event.target.files).map((file) => ({
      file,
      url: URL.createObjectURL(file),
      name: file.name
    }));

    if (files.length > 10 && user.isPro === false) {
      toast({
        title: 'Error',
        description: "You can only upload up to 10 images at a time. Please upgrade to Pro for unlimited uploads.",
        status: 'error',
        duration: 9000,
        isClosable: true,
        position: 'top',
      });
      // Clear the input
      event.target.value = '';
      return;
    }
    
    setSelectedFiles(files);
    setProgress(0);
    setDownloadUrl('');
  };

  const handleFilesAdded = (files) => {
    if (files.length > 10 && user.isPro === false) {
      toast({
        title: 'Error',
        description: "You can only upload up to 10 images at a time. Please upgrade to Pro for unlimited uploads.",
        status: 'error',
        duration: 9000,
        isClosable: true,
        position: 'top',
      });
      // Clear the input
      files = [];
      return;
    }

    // Filter all files, and leave only images
    files = files.filter(file => file.type.startsWith('image/'));
    setSelectedFiles(files.map(file => ({
      file,
      url: URL.createObjectURL(file)
    })));

    setProgress(0);
    setDownloadUrl('');
  }

  const compressImages = () => {
    const zip = new JSZip();
    let processedCount = 0;

    selectedFiles.forEach((imageFile) => {
      new Compressor(imageFile.file, {
        quality: 0.6, // Initial quality for compression
        maxHeight: 1920, // Maximum height for output image
        maxWidth: 1080, // Maximum width for output image
        maxSizeMB: maxSize / 1024, // Converts maxSize from KB to MB
        convertSize: 500000, // Converts PNGs over 500KB to JPEGs unless they are smaller than maxSize
        success: (compressedResult) => {
          zip.file(imageFile.name, compressedResult, { binary: true });
          processedCount++;
          setProgress((processedCount / selectedFiles.length) * 100);
          if (processedCount === selectedFiles.length) {
            zip.generateAsync({ type: "blob" }).then(function(content) {
              const url = URL.createObjectURL(content);
              setDownloadUrl(url);
            });
          }
        },
        error(err) {
          console.error('Compression Error:', err.message);
        },
      });
    });

    // Call updateResizeCount inside the resizing promise resolution
    updateResizeCount(selectedFiles.length).then(() => {
      console.log('Updated resize count');
    }).catch(error => {
      console.error('Error updating resize count', error);
    });
  };

  const handleDownload = () => {
    const anchor = document.createElement('a');
    anchor.href = downloadUrl;
    anchor.download = "compressed_images.zip";
    document.body.appendChild(anchor);
    anchor.click();
    document.body.removeChild(anchor);
  };

  const clearImages = () => {
    setSelectedFiles([]);
    setDownloadUrl('');
    setProgress(0);
  };

  const handleMaxSizeChange = (valueAsString, valueAsNumber) => {
    setMaxSize(valueAsNumber);
  };

  const removeImage = (index) => {
    const newSelectedFiles = selectedFiles.filter((_, i) => i !== index);
    setSelectedFiles(newSelectedFiles);
  };

  return (
    <Box>
      <Input 
        type="file" 
        accept="image/jpeg, image/png" 
        multiple 
        onChange={handleFileChange} 
        p={2} 
        ref={fileInputRef}
        style={{ display: 'none' }} // Hide the default file input
      />
      <Button onClick={handleButtonClick}>Upload Images</Button>
      <FileDropzone onFilesAdded={handleFilesAdded} />
      <FormControl pb={4} id="max-size-input" mt={4}>
        <FormLabel>Max Size in KB</FormLabel>
        <NumberInput min={1} defaultValue={500} onChange={handleMaxSizeChange}>
          <NumberInputField />
        </NumberInput>
      </FormControl>
      <Button colorScheme="purple" onClick={compressImages} isDisabled={selectedFiles.length === 0}>Compress Images</Button>
      <Button ml={2} onClick={handleDownload} isDisabled={!downloadUrl}>Download Zip</Button>
      <Button ml={2} colorScheme="red" onClick={clearImages}>Clear</Button>
      {selectedFiles.length > 0 && (
        <Box mt={4}>
          <Progress value={progress} size="sm" colorScheme="purple" />
          <Text mb={2}>Preview:</Text>
          <SimpleGrid columns={[3, 3, 6]} spacing={4}>
            {selectedFiles.map((file, index) => (
                <Box position="relative" key={index}>
                <Button 
                    position="absolute"
                    right="-2" 
                    top="-2" 
                    size="xs" 
                    colorScheme="red" 
                    onClick={() => removeImage(index)}
                >
                    x
                </Button>
                <ChakraImage src={file.url} alt={file.name} size="100px" objectFit="cover" borderRadius={5} />
                </Box>
            ))}
          </SimpleGrid>
        </Box>
      )}
    </Box>
  );
};

export default ImageCompressionTab;
