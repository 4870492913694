import React from 'react';
import { useDropzone } from 'react-dropzone';
import { Text, VStack, Icon } from '@chakra-ui/react';
import { FaFileUpload } from 'react-icons/fa';

const FileDropzone = ({ onFilesAdded }) => {
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: acceptedFiles => {
      onFilesAdded(acceptedFiles.map(file => Object.assign(file, {
        preview: URL.createObjectURL(file)
      })));
    }
  });

  return (
    <VStack
      {...getRootProps()}
      p={5}
      mt={5}
      w="full"
      border="2px dashed"
      color={isDragActive ? "blue.400" : "gray.400"}
      cursor="pointer"
      borderRadius="md"
      spacing={2}
      textAlign="center"
      transition="background-color 0.3s ease"
    >
      <input
        accept="image/jpeg, image/png" 
        {...getInputProps()} />
      <Icon as={FaFileUpload} w={12} h={12} />
      <Text>Drag 'n' drop some files here, or click to select files</Text>
      <Text>Only JPEG and PNG supported</Text>
    </VStack>
  );
};

export default FileDropzone;
