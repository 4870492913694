import React, { useEffect } from 'react';
import { Box, useToast  } from '@chakra-ui/react';
import Header from './components/Header';
import Footer from './components/Footer';
import MainContainer from './components/MainContainer';
import InfoComponent from './components/InfoComponent';
import StatisticComponent from './components/StatisticComponent';
import { useLocation } from 'react-router-dom';
import { SpeedInsights } from "@vercel/speed-insights/react"

function App() {
  const toast = useToast();
  const location = useLocation();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const status = searchParams.get('status');
    
    // Check for 'status' query parameter and show appropriate toast
    if (status) {
      if (status === 'success') {
        toast({
          title: "Payment Successful",
          description: "Thank you for your purchase. You are now a PRO user!",
          status: "success",
          duration: 9000,
          isClosable: true,
          position: "top"
        });
      } else if (status === 'cancel') {
        toast({
          title: "Payment Cancelled",
          description: "You cancelled the payment process.",
          status: "warning",
          duration: 9000,
          isClosable: true,
          position: "top"
        });
      }
    }
  }, [location, toast]);

  return (
    <Box minH="100vh" display="flex" flexDirection="column">
      <Header />
      <InfoComponent />
      <MainContainer />
      <StatisticComponent />
      <Footer />
      <SpeedInsights /> 
    </Box>
  );
}

export default App;
