import React,  { useState } from 'react';
import { FaCheckCircle } from 'react-icons/fa';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Text,
  useDisclosure,
  Box,
  useToast
} from '@chakra-ui/react';
import { loadStripe } from '@stripe/stripe-js';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { useUser } from '../context/UserContext';

// Load your stripe public key here
const stripePromise = loadStripe('pk_live_51P9rQEEDJcNV4FKwswyer13VrRP0tB7mcAHYPeR8QcPUmWGGqqaCjkZPazYxPM3zdnhH0RTms8Mc2ljhrZSKCrSa00ZT85F3NG');

const ProPlanModal = ({buttonText}) => {
  const { user, loading } = useUser();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isBuying, setIsBuying] = useState(false);
  const toast = useToast();

  const handleBuyPro = async () => {
    if (!user) {
      toast({
        title: 'Error',
        description: "You need to sign in to upgrade to PRO.",
        status: 'error',
        duration: 9000,
        isClosable: true,
        position: 'top',
      });
      return;
    }
    setIsBuying(true);
    const stripe = await stripePromise;
    const functions = getFunctions();
    const createCheckoutSession = httpsCallable(functions, 'createCheckoutSession');
    const session = await createCheckoutSession({ userId: user.Id });

    if (session.data) {
        console.log('Session:', session.data);
        const result = stripe.redirectToCheckout({ sessionId: session.data.sessionId });
        if (result.error) {
            console.log(result.error.message);
        }
    } else {
        console.log('Error:', session.error.message);
    }
    setIsBuying(false);
  };

  return (
    <>
      <Button onClick={onOpen} mt={3} colorScheme="purple">{buttonText}</Button>
      <Modal isOpen={isOpen} onClose={onClose} motionPreset="slideInBottom">
        <ModalOverlay />
        <ModalContent> {/* Changing color scheme */}
          <ModalHeader fontSize="3xl" fontWeight="bold" p={5}> {/* Making header larger and adding padding */}
            Upgrade to PRO and Boost Your Productivity!
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody align="center" p={10}> {/* Adding padding */}
            <img src="/logo_large.png" alt="Pro Plan" style={{ width: '80%', height: 'auto' }} />
            <Text mt={5} fontSize="lg" fontWeight="semibold">Get access to advanced features:</Text>
            <Box mt={4}>
              <ul style={{ listStyleType: 'none', paddingLeft: 0 }}> {/* Removing padding */}
                <li style={{ display: 'flex', alignItems: 'center' }}>
                    <FaCheckCircle pr={1} color="green.500" /> 
                    <span style={{paddingLeft: '5px'}}>Resize up to 200 files per batch</span>
                </li>
                <li style={{ display: 'flex', alignItems: 'center' }}>
                    <FaCheckCircle pr={1} color="green.500" /> 
                    <span style={{paddingLeft: '5px'}}>Image Compression Access</span>
                </li>
                <li style={{ display: 'flex', alignItems: 'center' }}>
                    <FaCheckCircle pr={1} color="green.500" /> 
                    <span style={{paddingLeft: '5px'}}>More Resize Options</span>
                </li>
                <li style={{ display: 'flex', alignItems: 'center' }}>
                    <FaCheckCircle pr={1} color="green.500" /> 
                    <span style={{paddingLeft: '5px'}}>Lifetime Updates</span>
                </li>
                <li style={{ display: 'flex', alignItems: 'center' }}>
                    <FaCheckCircle pr={1} color="green.500" /> 
                    <span style={{paddingLeft: '5px'}}>
                        Just 
                        <del>
                            <Text pl={2} pr={2} as="span" fontSize="xl" fontWeight="bold">$6.49</Text>
                        </del> 
                        <Text pr={2} as="span" fontSize="xl" fontWeight="bold" color="yellow.500">$0.99</Text> 
                        forever!
                    </span>
                </li>
              </ul>
            </Box>
          </ModalBody>
          <ModalFooter>
            <Button 
            colorScheme="purple" 
            mr={3} 
            onClick={handleBuyPro} 
            size="lg" 
            _hover={{ transform: 'scale(1.02)' }}
            isLoading={isBuying}
            loadingText="Processing..."
            > {/* Changing button color and adding hover effect */}
              Buy PRO Now
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default ProPlanModal;
