import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';

const firebaseConfig = {
    apiKey: "AIzaSyDAMo7qCaPO2EW4gVFbCky61wcsC4Tahd4",
    authDomain: "resizer-46c60.firebaseapp.com",
    projectId: "resizer-46c60",
    storageBucket: "resizer-46c60.appspot.com",
    messagingSenderId: "397877700795",
    appId: "1:397877700795:web:756e726ce5dae7c45ae19e",
    measurementId: "G-KYC825Y7PC"
};

firebase.initializeApp(firebaseConfig);

export const auth = firebase.auth();
export const firestore = firebase.firestore();
export default firebase;