import React, { useEffect, useState } from 'react';
import { Box, Text } from '@chakra-ui/react';
import { firestore } from '../firebaseConfig';

const StatisticComponent = () => {
  const [stats, setStats] = useState({ resizedCount: 0 });

  useEffect(() => {
    const statsRef = firestore.doc('stats/imageStats');
    const unsubscribe = statsRef.onSnapshot(snapshot => {
      if (snapshot.exists) {
        setStats(snapshot.data());
      }
    }, error => {
      console.log('Error fetching stats:', error);
    });

    return () => unsubscribe();
  }, []);

  return (
    <Box p={5} borderRadius="md" mb={4} textAlign="center">
      <Text fontSize="lg">Image Processing Statistics</Text>
      <Text>Images Processed: {stats.resizedCount}</Text>
    </Box>
  );
};

export default StatisticComponent;
